import React from 'react'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentDeliveryTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderNL = (
    <>
      <h3>LEVERINGSKOSTEN</h3>
      <ul>
        <li><span><strong>België: gratis vanaf €70</strong></span><br />Koop je een item onder de €70? Dan betaal je voor een verzending binnen België €6,95. En bij bestellingen boven €70 krijg je van ons gratis verzending! De enige uitzondering hierop is meubeltransport, daar betaal je gemiddeld €55 verzendkosten&nbsp;(tot aan jouw&nbsp;deur,&nbsp;op de begane grond). Indien dit van toepassing is, zie je dit bij het product zelf vermeld staan.&nbsp;<br /><br /></li>
        <li><span><strong>Nederland: gratis vanaf €70</strong></span><br />Koop je een item onder de €70? Dan betaal je voor een verzending naar nederlands €7,95. En bij bestellingen boven €70 krijg je van ons gratis verzending! De enige uitzondering hierop is meubeltransport, daar betaal je gemiddeld €55 verzendkosten&nbsp;(tot aan jouw&nbsp;deur,&nbsp;op de begane grond). Indien dit van toepassing is, zie je dit bij het product zelf vermeld staan.&nbsp;<br /><br /></li>
        <li><span><strong>Europa</strong></span><br />Shop Up North verzendt pakketjes naar Luxemburg, Duitsland, Frankrijk, Groot-Brittanië en andere landen. De prijs verschilt van land tot land.<br />Wil je het naar een ander land versturen? Neem dan even <a href="/nl/contact">contact</a>&nbsp;met ons op, en we bekijken samen wat we voor jou kunnen doen!&nbsp;</li>
      </ul>
      <p>Het klinkt logisch, maar we geven het toch voor alle zekerheid nog even mee: wij&nbsp;raden je aan om al&nbsp;jouw&nbsp;artikelen onder te brengen in één bestelling. We kunnen twee apart geplaatste bestellingen niet meer samenvoegen, dus voor elke bestelling zullen verzendkosten in rekening worden gebracht.</p>
      <p><br /><br /></p>
      <h3><span>VERZEND- EN LEVERTERMIJN&nbsp;VAN JOUW PAKKET</span></h3>
      <p>Indien jouw artikel voorradig is, en je plaatst en betaalt jouw bestelling voor&nbsp;15u, dan sturen wij doorgaans jouw bestelling dezelfde dag nog op! In uitzonderlijke gevallen&nbsp;gebeurt het dat hier een dag langer over gaat, maar de verzending gebeurt sowieso binnen max. 2 werkdagen tenzij het anders vermeld staat. Alleszins krijg je van ons een mailtje met een track&amp;trace code, zodanig dat je weet wanneer je jouw pakketje ongeveer mag verwachten.</p>
      <p>Bij artikelen die niet voorradig zijn, hanteren we een verzendtermijn van 1 à 2 weken. Indien dit van toepassing is, staat dit bij het specifieke product vermeld bij levertermijn.&nbsp;Zo vermijden we onaangename verrassingen!&nbsp;</p>
      <p>Voor de aflevering zelf zijn we natuurlijk afhankelijk van de transportmaatschappijen, maar binnen België&nbsp;mag je jouw pakket toch zeker na 2 à 3 werkdagen na verzending verwachten.&nbsp;<br /><br /><br /></p>
      <h3><span>VERZENDMETHODE</span>&nbsp;</h3>
      <p>Wij versturen de pakketten met tracking en aflevering zonder handtekening. Shop Up North besteedt ook de uiterste zorg aan het inpakken en beschermen van jouw bestelling. Onze dozen zijn voldoende groot, en bubbelfolie hebben we àltijd op voorraad ;)&nbsp;</p>
    </>
  )

  const renderEN = (
    <>
      <h3>DELIVERY COSTS</h3>
      <ul>
        <li><span><strong>Belgium: free from €70</strong></span><br />Do you buy an item under €70? Then you pay €6.95 for a shipment within Belgium. And for orders over €70 you get free shipping! The only exception is furniture transport, where you pay an average of € 35 shipping costs (up to your door, on the ground floor). If applicable, you will see this on the product itself.&nbsp;<br /><br /></li>
        <li><span><strong>Netherlands: free from €70</strong></span><br />Do you buy an item under €70? Then you pay for a shipping to Dutch €7.95. And for orders over €70 you get free shipping! The only exception is furniture transport, where you pay an average of € 35 shipping costs (up to your door, on the ground floor). If applicable, you will see this on the product itself. <br /><br /></li>
        <li><span><strong>Europa</strong></span><br />Shop Up North sends parcels to Luxembourg, Germany, France, Great Britain, and others. Prices depend on the destination.<br />Do you want to send it to another country? Please <a href="/en/contact">contact us</a>, and we'll see what we can do for you!</li>
      </ul>
      <p>It sounds logical, but we still give it for a while: we advise you to put all your items in one order. We can no longer combine two separately placed orders, so shipping costs will be charged for each order.</p>
      <br /><br />
      <h3><span>SHIPPING YOUR PACKAGE</span></h3>
      <p>If your item is in stock, and you place and pay for your order before 3pm, we usually send your order the same day! In exceptional cases, it happens that this takes a day longer, but the shipment will take place no later than within 2 working days unless stated otherwise. In any case, you will receive an e-mail with a track &amp; trace code, so that you know when you can expect your package.</p>
      <p>For articles that are not in stock, we use a delivery period of 1 to 2 weeks. If applicable, this is stated with the specific product at the delivery date. This way we avoid unpleasant surprises!</p>
      <p>For the delivery itself we are of course dependent on the transport companies, but within Belgium you can certainly expect your package after 2 to 3 working days after shipment.<br /><br /><br /></p>
      <h3><span>SHIPPING METHOD</span></h3>
      <p>We send the packages with tracking and delivery without signature. Shop Up North also devotes the utmost care to packing and protecting your order. Our boxes are large enough, and bubble foil we always have in stock;)</p>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentDeliveryTemplate
